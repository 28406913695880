import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../common/UI/Loader';
import ViewOrderPage from '../components/Orders/ViewOrder';
import ViewOrderProductPage from '../components/Orders/ViewOrderProduct';
import {
  getOrderById,
  getPharmacistsAndDoctors,
} from '../store/reducers/order';
import { isUserAdmin, isUserDoctor, isUserPharmacist } from '../utils/helpers';

const ViewOrder = () => {
  const [isAccessible, setIsAccessible] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [stateLoading, setStateLoading] = useState(true);
  const { orderData, loading } = useSelector((state) => state.order);
  useEffect(() => {
    dispatch(getOrderById(id));
    if (isUserAdmin() || isUserPharmacist()) {
      dispatch(getPharmacistsAndDoctors());
    }
  }, [id]);
  useEffect(() => {
    if (isUserPharmacist()) {
      if (orderData) {
        const loggedInUser = localStorage.getItem('userId');
        if (
          isUserPharmacist() &&
          (!orderData?.pharmacist_id ||
            (orderData?.pharmacist_id &&
              orderData?.pharmacist_id !== loggedInUser))
        ) {
          setIsAccessible(false);
          setStateLoading(false);
        } else {
          setIsAccessible(true);
          setStateLoading(false);
        }
      }
    } else if (isUserAdmin() || isUserDoctor()) {
      setStateLoading(loading);
      setIsAccessible(true);
    }
  }, [orderData, loading]);
  return (
    <div className='content_wrapper all_products_main'>
      <div className='container-fluid'></div>
      {stateLoading ? (
        <Loader loading={stateLoading} />
      ) : isAccessible ? (
        <ViewOrderPage />
      ) : (
        <h1 className='no_access'>Sorry user, No page for you!</h1>
      )}
    </div>
  );
};

export default ViewOrder;
