import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ROLE_TYPES, GENDER_OPTIONS, DIMENSION } from '../common/constants';
import ReactSelect from './../common/FormComponents/ReactSelect';
import Input from '../common/FormComponents/Input';
import ImageInput from '../common/FormComponents/ImageInput';
import services from '../api/api';
import { toastSuccess, toastError } from '../common/UI/Toasts';
import {
  getErrorMessage,
  RoleByKey,
  GenderByKey,
  getFormDataFromObject,
} from '../utils/helpers';

import viewIcon from '../assets/images/eye.svg';
import crossViewIcon from '../assets/images/eye-off.svg';
import TextError from '../common/FormComponents/TextError';

const AddAdminDoctor = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [email, setEmail] = useState('');
  const UID = searchParams.get('id');
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState();
  const [loadFormValues, setLoadFormValues] = useState(null);
  const initialFormValues = {
    fname: '',
    lname: '',
    email: '',
    role: null,
    gender: null,
    phone: '',
    photo: [],
    password: '',
    doctor_e_sign: '',
    flat_or_block_no: '',
    address_line1: '',
    city: '',
    country: '',
    zip_code: '',
    pharmacist_gphc_number: '',
    doctor_medical_registration_number: '',
    //
    address_id: null,
    company_name: '',
  };

  const validationSchema = Yup.object({
    fname: Yup.string().required(getErrorMessage('required', 'First name')),
    lname: Yup.string().required(getErrorMessage('required', 'Last name')),
    email: Yup.string()
      .email(getErrorMessage('valid', 'Email'))
      .required(getErrorMessage('required', 'Email')),
    role: Yup.object()
      .nullable()
      .required(getErrorMessage('select-required', 'Role')),
    gender: Yup.object()
      .nullable()
      .required(getErrorMessage('select-required', 'Gender')),
    phone: Yup.string()
      .matches(/^.{9,11}$/, getErrorMessage('phoneformat', 'Phone'))
      .required(getErrorMessage('required', 'Phone')),
    password:
      loadFormValues && loadFormValues.id
        ? Yup.string().matches(
            /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            getErrorMessage('passstrength', 'Password'),
          )
        : Yup.string()
            .matches(
              /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
              getErrorMessage('passstrength', 'Password'),
            )
            .required(getErrorMessage('required', 'Password')),
    flat_or_block_no: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'pharmacist',
      then: Yup.string().required(
        getErrorMessage('required', 'Flat or Block No'),
      ),
    }),
    address_line1: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'pharmacist',
      then: Yup.string().required(getErrorMessage('required', 'Address')),
    }),
    city: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'pharmacist',
      then: Yup.string().required(getErrorMessage('required', 'City')),
    }),
    country: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'pharmacist',
      then: Yup.string().required(getErrorMessage('required', 'Country')),
    }),
    zip_code: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'pharmacist',
      then: Yup.string().required(getErrorMessage('required', 'Postcode')),
    }),
    pharmacist_gphc_number: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'pharmacist',
      then: Yup.string().required(getErrorMessage('required', 'GPHC Number')),
    }),
    company_name: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'pharmacist',
      then: Yup.string().required(getErrorMessage('required', 'Company Name')),
    }),
    doctor_medical_registration_number: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'doctor',
      then: Yup.string().required(
        getErrorMessage('required', 'Medical Registration Number'),
      ),
    }),
    doctor_e_sign: Yup.string().when('role', {
      is: (selectProps) => selectProps && selectProps.value == 'doctor',
      then: Yup.string().required(getErrorMessage('required', 'E-Sign')),
    }),
  });

  let schemaForEmailOnly = Yup.object().shape({
    email: Yup.string().email(),
  });

  const checkUserEmail = () => {
    schemaForEmailOnly
      .isValid({
        email: email,
      })
      .then((valid) => {
        if (valid && email != '') {
          services
            .checkIfUserExists({ email: email })
            .then((res) => {
              if (
                res.data.data.isExist == true ||
                res.data.data.isExist == 'true'
              )
                setUserAlreadyExists(true);
              else setUserAlreadyExists(false);
            })
            .catch((err) => {
              console.log('err', err);
            });
        }
      });
  };

  const onSubmit = (values, onSubmitProps) => {
    if (userAlreadyExists) {
      toastError('User with this email already exists.');
      return;
    }
    const payload = {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      role: values.role.value,
      gender: values.gender.value,
      phone: values.phone,
      address: {
        flat_or_block_no: values.flat_or_block_no,
        address_line1: values.address_line1,
        city: values.city,
        country: values.country,
        zip_code: values.zip_code,
      },
      pharmacist_gphc_number: values.pharmacist_gphc_number,
      doctor_medical_registration_number:
        values.doctor_medical_registration_number,
      doctor_e_sign: values.doctor_e_sign,
      company_name: values.company_name,
    };
    if (values.address_id) {
      payload.address._id = values.address_id;
    }

    if (values && values.id) {
      const updatePayload = {};
      if (values && values.password) {
        updatePayload.password = values.password;
      }
      if (values && values.photo.length > 0 && values.photo[0].imgData) {
        updatePayload.photo = values.photo[0].imgData;
      }
      services
        .updateUserById(
          values.id,
          getFormDataFromObject({ ...payload, ...updatePayload }),
        )
        .then((res) => {
          if (res) {
            toastSuccess('Updated Successfully!!');
            onSubmitProps.setSubmitting(false);
            navigate('/admin-doctor');
          }
        })
        .catch((err) => {
          toastError(err.message);
          onSubmitProps.setSubmitting(false);
        });
    } else {
      const registerPayload = {
        password: values.password,
        photo: values?.photo[0]?.imgData,
      };
      services
        .register(
          getFormDataFromObject({
            ...payload,
            ...registerPayload,
          }),
        )
        .then((res) => {
          if (res) {
            toastSuccess('Added Successfully!!');
            onSubmitProps.resetForm();
            onSubmitProps.setSubmitting(false);
            navigate('/admin-doctor');
          }
        })
        .catch((err) => {
          toastError(err.message);
          onSubmitProps.setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (UID) {
      services
        .getUserById(UID)
        .then((res) => {
          const data = res?.data?.data;
          setRole(data?.role);
          if (
            data &&
            (data?.role === 'doctor' ||
              data?.role === 'admin' ||
              data?.role === 'pharmacist')
          ) {
            let assignFormValues = {
              id: data?._id,
              fname: data?.first_name,
              lname: data?.last_name,
              email: data?.email,
              role: {
                label: RoleByKey[data?.role],
                value: data?.role,
              },
              gender: {
                label: GenderByKey[data?.gender],
                value: data?.gender,
              },
              phone: data?.phone,
              photo: [
                {
                  PrevUrl: data?.photo?.location,
                  imgData: null,
                },
              ],
              password: '',
              doctor_e_sign: data?.doctor_e_sign || '',
            };
            if (data?.role === 'pharmacist') {
              if (data.address && data.address[0]) {
                const address = data.address[0];
                assignFormValues.address_id = address._id;
                assignFormValues.flat_or_block_no = address.flat_or_block_no;
                assignFormValues.address_line1 = address.address_line1;
                assignFormValues.city = address.city;
                assignFormValues.country = address.country;
                assignFormValues.zip_code = address.zip_code;
                assignFormValues.pharmacist_gphc_number =
                  data?.pharmacist_gphc_number;
                assignFormValues.company_name = data?.company_name;
              }
            } else if (data.role === 'doctor') {
              assignFormValues.doctor_medical_registration_number =
                data?.doctor_medical_registration_number;
              assignFormValues.doctor_e_sign = data?.doctor_e_sign;
            }
            setLoadFormValues(assignFormValues);
          } else {
            toastError('User not allowed');
          }
        })
        .catch((err) => {
          toastError(err.message);
        });
    }
  }, []);

  const toggleShowPassword = () => {
    setShowPassword((pre) => !pre);
  };
  return (
    <section className="content_wrapper product_add_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link to={'/admin-doctor'} title="Product Management">
                  Admin/Doctor/Pharmacist Management
                </Link>
              </li>
              <li>
                {loadFormValues && loadFormValues.id ? 'Edit' : 'Add'}{' '}
                Admin/Doctor/Pharmacist
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>
                  {loadFormValues && loadFormValues.id ? 'Edit' : 'Add'}{' '}
                  Admin/Doctor/Pharmacist
                </h3>
              </div>
            </div>
          </div>
        </div>
        <Formik
          initialValues={loadFormValues || initialFormValues}
          onSubmit={onSubmit}
          validateOnChange={false}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(formik) => {
            const { setFieldValue, errors, setTouched, touched } = formik;
            return (
              <Form>
                <div className="middle_main_2">
                  <div className="row">
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                      <Input
                        label="First Name"
                        labelClassname="form-label"
                        name="fname"
                        id="fname"
                        type="text"
                        className="form-control form-input"
                        placeholder="First Name"
                        isRequired
                      />
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                      <Input
                        label="Last Name"
                        labelClassname="form-label"
                        name="lname"
                        id="lname"
                        type="text"
                        className="form-control form-input"
                        placeholder="Last Name"
                        isRequired
                      />
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                      <label htmlFor={'email'}>
                        Email
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        id={'email'}
                        className={'form-control form-input'}
                        type={'text'}
                        name={'email'}
                        placeholder={'Email Address'}
                        onChange={(e) => {
                          setFieldValue('email', e.target.value);
                          setEmail(e.target.value);
                        }}
                        onBlur={(e) => {
                          setTouched({ ...touched, email: true });
                          if (e.target.value == '' || errors.email)
                            setUserAlreadyExists(false);
                          else checkUserEmail();
                        }}
                      />
                      <ErrorMessage component={TextError} name={'email'} />
                      {userAlreadyExists ? (
                        <span className="text-danger d-block">
                          This email already exists, Please login.
                        </span>
                      ) : null}
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                      <ReactSelect
                        label="Select Role"
                        labelClassname="form-label"
                        name="role"
                        id="role"
                        placeholder="Select Role"
                        className="form-select"
                        options={ROLE_TYPES}
                        height={'40px'}
                        onChangeAction={(value) => {
                          setRole(value.value);
                        }}
                        isRequired
                      />
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                      <ReactSelect
                        label="Select Gender"
                        labelClassname="form-label"
                        name="gender"
                        id="gender"
                        placeholder="Select Gender"
                        className="form-select"
                        options={GENDER_OPTIONS}
                        height={'40px'}
                        isRequired
                      />
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                      <Input
                        label="Phone"
                        labelClassname="form-label"
                        name="phone"
                        id="phone"
                        type="text"
                        className="form-control form-input"
                        placeholder="Phone"
                        isRequired
                      />
                    </div>
                    <div
                      className={`col-xl-4 col-md-6 col-lg-6 form-group position-relative`}
                    >
                      <Input
                        id={'password'}
                        label={
                          loadFormValues && loadFormValues?.id
                            ? 'Change Password'
                            : 'Password'
                        }
                        labelClassname="form-label"
                        className="form-control form-input"
                        type={showPassword ? 'text' : 'password'}
                        name={'password'}
                        placeholder={'Password'}
                        isRequired
                      />
                      <span
                        onClick={toggleShowPassword}
                        className={`position-absolute`}
                        style={{ right: '30px', top: '46px' }}
                      >
                        <img
                          src={showPassword ? viewIcon : crossViewIcon}
                          alt="eye"
                          width="24"
                          height="24"
                        />
                      </span>
                    </div>
                    {role === 'pharmacist' ? (
                      <>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="Flat or Block No"
                            labelClassname="form-label"
                            name="flat_or_block_no"
                            id="flat_or_block_no"
                            type="text"
                            className="form-control form-input"
                            placeholder="Flat or Block No"
                            isRequired
                            disabled={formik.values.address_id}
                          />
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="Address"
                            labelClassname="form-label"
                            name="address_line1"
                            id="address_line1"
                            type="text"
                            className="form-control form-input"
                            placeholder="Address"
                            isRequired
                            disabled={formik.values.address_id}
                          />
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="City"
                            labelClassname="form-label"
                            name="city"
                            id="city"
                            type="text"
                            className="form-control form-input"
                            placeholder="City"
                            isRequired
                            disabled={formik.values.address_id}
                          />
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="Country"
                            labelClassname="form-label"
                            name="country"
                            id="country"
                            type="text"
                            className="form-control form-input"
                            placeholder="Country"
                            isRequired
                            disabled={formik.values.address_id}
                          />
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="Postcode"
                            labelClassname="form-label"
                            name="zip_code"
                            id="zip_code"
                            type="text"
                            className="form-control form-input"
                            placeholder="Postcode"
                            isRequired
                            disabled={formik.values.address_id}
                          />
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="GPHC Number"
                            labelClassname="form-label"
                            name="pharmacist_gphc_number"
                            id="pharmacist_gphc_number"
                            type="text"
                            className="form-control form-input"
                            placeholder="GPHC Number"
                            isRequired
                          />
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="Company Name"
                            labelClassname="form-label"
                            name="company_name"
                            id="company_name"
                            type="text"
                            className="form-control form-input"
                            placeholder="Company Name"
                            isRequired
                          />
                        </div>
                      </>
                    ) : null}
                    {role === 'doctor' ? (
                      <>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="E-Sign"
                            labelClassname="form-label"
                            name="doctor_e_sign"
                            id="doctor_e_sign"
                            type="text"
                            className="form-control form-input"
                            placeholder="E-Sign"
                            isRequired
                          />
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                          <Input
                            label="Medical Registration Number"
                            labelClassname="form-label"
                            name="doctor_medical_registration_number"
                            id="doctor_medical_registration_number"
                            type="text"
                            className="form-control form-input"
                            placeholder="Medical Registration Number"
                            isRequired
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  <ImageInput
                    label={'Upload Profile Image'}
                    subLabel={`Upload Image : (w x h :${DIMENSION.profile.width}x${DIMENSION.profile.height})`}
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    name={'photo'}
                    id={'photo'}
                    imagesLimit={1}
                    imageDimention={DIMENSION.profile}
                  />
                </div>
                <div className="footer_main">
                  <Button
                    type="button"
                    variant="sky"
                    className="my-2 me-2"
                    onClick={() => navigate('/admin-doctor')}
                  >
                    Back
                  </Button>
                  <Button className="me-2" type="submit">
                    {loadFormValues && loadFormValues.id ? 'Update' : 'Submit'}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default AddAdminDoctor;
