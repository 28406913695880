import moment from 'moment';
import topBanner from '../assets/images/top_layer.png';
import emailIcon from '../assets/images/icon1.png';
import phoneIcon from '../assets/images/icon2.png';
import bottomBanner from '../assets/images/bottom.png';

export const PRODUCT_TYPES = [
  {
    label: 'Product',
    value: 'product',
  },
  {
    label: 'Medication',
    value: 'medication',
  },
];

export const PRODUCT_STATUS = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
];

export const ROLE_TYPES = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Doctor',
    value: 'doctor',
  },
  {
    label: 'Pharmacist',
    value: 'pharmacist',
  },
];

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];

export const COUNTRY_OPTIONS = [
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
    country_code: 'AE',
  },
  { label: 'Afghanistan', value: 'Afghanistan', country_code: 'AF' },
  { label: 'Albania', value: 'Albania', country_code: 'AL' },
  { label: 'Algeria', value: 'Algeria', country_code: 'DZ' },
  { label: 'American Samoa', value: 'American Samoa', country_code: 'AS' },
  { label: 'Andorra', value: 'Andorra', country_code: 'AD' },
  { label: 'Angola', value: 'Angola', country_code: '	AO' },
  { label: 'Anguilla', value: 'Anguilla', country_code: 'AI' },
  { label: 'Antarctica', value: 'Antarctica', country_code: 'AQ' },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
    country_code: 'AG',
  },
  { label: 'Argentina', value: 'Argentina', country_code: 'AR' },
  { label: 'Armenia', value: 'Armenia', country_code: 'AM' },
  { label: 'Aruba', value: 'Aruba', country_code: 'AW' },
  { label: 'Australia', value: 'Australia', country_code: 'AU' },
  { label: 'Austria', value: 'Austria', country_code: 'AT' },
  { label: 'Azerbaijan', value: 'Azerbaijan', country_code: 'AZ' },
  { label: 'Bahamas', value: 'Bahamas', country_code: 'BS' },
  { label: 'Bahrain', value: 'Bahrain', country_code: 'BH' },
  { label: 'Bangladesh', value: 'Bangladesh', country_code: 'BD' },
  { label: 'Barbados', value: 'Barbados', country_code: 'BB' },
  { label: 'Belarus', value: 'Belarus', country_code: 'BY' },
  { label: 'Belgium', value: 'Belgium', country_code: 'BE' },
  { label: 'Belize', value: 'Belize', country_code: 'BZ' },
  { label: 'Benin', value: 'Benin', country_code: 'BJ' },
  { label: 'Bermuda', value: 'Bermuda', country_code: 'BM' },
  { label: 'Bhutan', value: 'Bhutan', country_code: 'BT' },
  { label: 'Bolivia', value: 'Bolivia', country_code: 'BO' },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
    country_code: 'BA',
  },
  { label: 'Botswana', value: 'Botswana', country_code: 'BW' },
  { label: 'Brazil', value: 'Brazil', country_code: 'BR' },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
    country_code: 'IO',
  },
  {
    label: 'British Virgin Islands',
    value: 'British Virgin Islands',
    country_code: 'VG',
  },
  { label: 'Brunei', value: 'Brunei', country_code: 'BN' },
  { label: 'Bulgaria', value: 'Bulgaria', country_code: 'BG' },
  { label: 'Burkina Faso', value: 'Burkina Faso', country_code: 'BF' },
  { label: 'Burundi', value: 'Burundi', country_code: 'BI' },
  { label: 'Cambodia', value: 'Cambodia', country_code: 'KH' },
  { label: 'Cameroon', value: 'Cameroon', country_code: 'CM' },
  { label: 'Canada', value: 'Canada', country_code: 'CA' },
  { label: 'Cape Verde', value: 'Cape Verde', country_code: 'CV' },
  { label: 'Cayman Islands', value: 'Cayman Islands', country_code: 'KY' },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
    country_code: 'CF',
  },
  { label: 'Chad', value: 'Chad', country_code: 'TD' },
  { label: 'Chile', value: 'Chile', country_code: 'CL' },
  { label: 'China', value: 'China', country_code: 'CN' },
  { label: 'Christmas Island', value: 'Christmas Island', country_code: 'CX' },
  { label: 'Cocos Islands', value: 'Cocos Islands', country_code: 'CC' },
  { label: 'Colombia', value: 'Colombia', country_code: 'CO' },
  { label: 'Comoros', value: 'Comoros', country_code: 'KM' },
  { label: 'Cook Islands', value: 'Cook Islands', country_code: 'CK' },
  { label: 'Costa Rica', value: 'Costa Rica', country_code: 'CR' },
  { label: 'Croatia', value: 'Croatia', country_code: 'HR' },
  { label: 'Cuba', value: 'Cuba', country_code: 'CU' },
  { label: 'Curacao', value: 'Curacao', country_code: 'CW' },
  { label: 'Cyprus', value: 'Cyprus', country_code: 'CY' },
  { label: 'Czech Republic', value: 'Czech Republic', country_code: 'CZ' },
  {
    label: 'Democratic Republic of the Congo',
    value: 'Democratic Republic of the Congo',
    country_code: 'CD',
  },
  { label: 'Denmark', value: 'Denmark', country_code: 'DK' },
  { label: 'Djibouti', value: 'Djibouti', country_code: 'DJ' },
  { label: 'Dominica', value: 'Dominica', country_code: 'DM' },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
    country_code: 'DO',
  },
  { label: 'East Timor', value: 'East Timor', country_code: 'TL' },
  { label: 'Ecuador', value: 'Ecuador', country_code: 'EC' },
  { label: 'Egypt', value: 'Egypt', country_code: 'EG' },
  { label: 'El Salvador', value: 'El Salvador', country_code: 'SV' },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    country_code: 'GQ',
  },
  { label: 'Eritrea', value: 'Eritrea', country_code: 'ER' },
  { label: 'Estonia', value: 'Estonia', country_code: 'EE' },
  { label: 'Ethiopia', value: 'Ethiopia', country_code: 'ET' },
  {
    label: 'Falkland Islands',
    value: 'Falkland Islands',
    country_code: 'FK',
  },
  {
    label: 'Faroe Islands',
    value: 'Faroe Islands',
    country_code: 'FO',
  },
  { label: 'Fiji', value: 'Fiji', country_code: 'FJ' },
  { label: 'Finland', value: 'Finland', country_code: 'FI' },
  { label: 'France', value: 'France', country_code: 'FR' },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
    country_code: 'PF',
  },
  { label: 'Gabon', value: 'Gabon', country_code: 'GA' },
  { label: 'Gambia', value: 'Gambia', country_code: 'GM' },
  { label: 'Georgia', value: 'Georgia', country_code: 'GE' },
  { label: 'Germany', value: 'Germany', country_code: 'DE' },
  { label: 'Ghana', value: 'Ghana', country_code: 'GH' },
  { label: 'Gibraltar', value: 'Gibraltar', country_code: 'GI' },
  { label: 'Greece', value: 'Greece', country_code: 'GR' },
  { label: 'Greenland', value: 'Greenland', country_code: 'GL' },
  { label: 'Grenada', value: 'Grenada', country_code: 'GD' },
  { label: 'Guam', value: 'Guam', country_code: 'GU' },
  { label: 'Guatemala', value: 'Guatemala', country_code: 'GT' },
  { label: 'Guernsey', value: 'Guernsey', country_code: 'GG' },
  { label: 'Guinea', value: 'Guinea', country_code: 'GN' },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
    country_code: 'GW',
  },
  { label: 'Guyana', value: 'Guyana', country_code: 'GY' },
  { label: 'Haiti', value: 'Haiti', country_code: 'HT' },
  { label: 'Honduras', value: 'Honduras', country_code: 'HN' },
  { label: 'Hong Kong', value: 'Hong Kong', country_code: 'HK' },
  { label: 'Hungary', value: 'Hungary', country_code: 'HU' },
  { label: 'Iceland', value: 'Iceland', country_code: 'IS' },
  { label: 'India', value: 'India', country_code: 'IN' },
  { label: 'Indonesia', value: 'Indonesia', country_code: 'ID' },
  { label: 'Iran', value: 'Iran', country_code: 'IR' },
  { label: 'Iraq', value: 'Iraq', country_code: 'IQ' },
  { label: 'Ireland', value: 'Ireland', country_code: 'IE' },
  {
    label: 'Isle of Man',
    value: 'Isle of Man',
    country_code: 'IM',
  },
  { label: 'Israel', value: 'Israel', country_code: 'IL' },
  { label: 'Italy', value: 'Italy', country_code: 'IT' },
  { label: 'Ivory Coast', value: 'Ivory Coast', country_code: 'CI' },
  { label: 'Jamaica', value: 'Jamaica', country_code: 'JM' },
  { label: 'Japan', value: 'Japan', country_code: 'JP' },
  { label: 'Jersey', value: 'Jersey', country_code: 'JE' },
  { label: 'Jordan', value: 'Jordan', country_code: 'JO' },
  { label: 'Kazakhstan', value: 'Kazakhstan', country_code: 'KZ' },
  { label: 'Kenya', value: 'Kenya', country_code: 'KE' },
  { label: 'Kiribati', value: 'Kiribati', country_code: 'KI' },
  { label: 'Kosovo', value: 'Kosovo', country_code: 'XK' },
  { label: 'Kuwait', value: 'Kuwait', country_code: 'KW' },
  { label: 'Kyrgyzstan', value: 'Kyrgyzstan', country_code: 'KG' },
  { label: 'Laos', value: 'Laos', country_code: 'LA' },
  { label: 'Latvia', value: 'Latvia', country_code: 'LV' },
  { label: 'Lebanon', value: 'Lebanon', country_code: 'LB' },
  { label: 'Lesotho', value: 'Lesotho', country_code: 'LS' },
  { label: 'Liberia', value: 'Liberia', country_code: 'LR' },
  { label: 'Libya', value: 'Libya', country_code: 'LY' },
  { label: 'Liechtenstein', value: 'Liechtenstein', country_code: 'LI' },
  { label: 'Lithuania', value: 'Lithuania', country_code: 'LT' },
  { label: 'Luxembourg', value: 'Luxembourg', country_code: 'LU' },
  { label: 'Macau', value: 'Macau', country_code: 'MO' },
  { label: 'Macedonia', value: 'Macedonia', country_code: 'MK' },
  { label: 'Madagascar', value: 'Madagascar', country_code: 'MG' },
  { label: 'Malawi', value: 'Malawi', country_code: 'MW' },
  { label: 'Malaysia', value: 'Malaysia', country_code: 'MY' },
  { label: 'Maldives', value: 'Maldives', country_code: 'MV' },
  { label: 'Mali', value: 'Mali', country_code: 'ML' },
  { label: 'Malta', value: 'Malta', country_code: 'MT' },
  { label: 'Marshall Islands', value: 'Marshall Islands', country_code: 'MH' },
  { label: 'Mauritania', value: 'Mauritania', country_code: 'MR' },
  { label: 'Mauritius', value: 'Mauritius', country_code: 'MU' },
  { label: 'Mayotte', value: 'Mayotte', country_code: 'YT' },
  { label: 'Mexico', value: 'Mexico', country_code: 'MX' },
  { label: 'Micronesia', value: 'Micronesia', country_code: 'FM' },
  { label: 'Moldova', value: 'Moldova', country_code: 'MD' },
  { label: 'Monaco', value: 'Monaco', country_code: 'MC' },
  { label: 'Mongolia', value: 'Mongolia', country_code: 'MN' },
  { label: 'Montenegro', value: 'Montenegro', country_code: 'ME' },
  { label: 'Montserrat', value: 'Montserrat', country_code: 'MS' },
  { label: 'Morocco', value: 'Morocco', country_code: 'MA' },
  { label: 'Mozambique', value: 'Mozambique', country_code: 'MZ' },
  { label: 'Myanmar', value: 'Myanmar', country_code: 'MM' },
  { label: 'Namibia', value: 'Namibia', country_code: 'NA' },
  { label: 'Nauru', value: 'Nauru', country_code: 'NR' },
  { label: 'Nepal', value: 'Nepal', country_code: 'NP' },
  { label: 'Netherlands', value: 'Netherlands', country_code: 'NL' },
  {
    label: 'Netherlands Antilles',
    value: 'Netherlands Antilles',
    country_code: 'AN',
  },
  { label: 'New Caledonia', value: 'New Caledonia', country_code: 'NC' },
  { label: 'New Zealand', value: 'New Zealand', country_code: 'NZ' },
  { label: 'Nicaragua', value: 'Nicaragua', country_code: 'NI' },
  { label: 'Niger', value: 'Niger', country_code: 'NE' },
  { label: 'Nigeria', value: 'Nigeria', country_code: 'NG' },
  { label: 'Niue', value: 'Niue', country_code: 'NU' },
  { label: 'North Korea', value: 'North Korea', country_code: 'KP' },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    country_code: 'MP',
  },
  { label: 'Norway', value: 'Norway', country_code: 'NO' },
  { label: 'Oman', value: 'Oman', country_code: 'OM' },
  { label: 'Pakistan', value: 'Pakistan', country_code: 'PK' },
  { label: 'Palau', value: 'Palau', country_code: 'PW' },
  { label: 'Palestine', value: 'Palestine', country_code: 'PS' },
  { label: 'Panama', value: 'Panama', country_code: 'PJ' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea', country_code: 'PG' },
  { label: 'Paraguay', value: 'Paraguay', country_code: 'PY' },
  { label: 'Peru', value: 'Peru', country_code: 'PE' },
  { label: 'Philippines', value: 'Philippines', country_code: 'PH' },
  { label: 'Pitcairn', value: 'Pitcairn', country_code: 'PN' },
  { label: 'Poland', value: 'Poland', country_code: 'PL' },
  { label: 'Portugal', value: 'Portugal', country_code: 'PT' },
  { label: 'Puerto Rico', value: 'Puerto Rico', country_code: 'PR' },
  { label: 'Qatar', value: 'Qatar', country_code: 'QA' },
  {
    label: 'Republic of the Congo',
    value: 'Republic of the Congo',
    country_code: 'CG',
  },
  { label: 'Reunion', value: 'Reunion', country_code: 'RE' },
  { label: 'Romania', value: 'Romania', country_code: 'RO' },
  { label: 'Russia', value: 'Russia', country_code: 'RU' },
  { label: 'Rwanda', value: 'Rwanda', country_code: 'RW' },
  { label: 'Saint Barthelemy', value: 'Saint Barthelemy', country_code: 'BL' },
  { label: 'Saint Helena', value: 'Saint Helena', country_code: 'SH' },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
    country_code: 'KN',
  },
  { label: 'Saint Lucia', value: 'Saint Lucia', country_code: 'LC' },
  { label: 'Saint Martin', value: 'Saint Martin', country_code: 'MF' },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
    country_code: 'PM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
    country_code: 'VC',
  },
  { label: 'Samoa', value: 'Samoa', country_code: 'WS' },
  { label: 'San Marino', value: 'San Marino', country_code: 'SM' },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
    country_code: 'ST',
  },
  { label: 'Saudi Arabia', value: 'Saudi Arabia', country_code: 'SA' },
  { label: 'Senegal', value: 'Senegal', country_code: 'SN' },
  { label: 'Serbia', value: 'Serbia', country_code: 'RS' },
  { label: 'Seychelles', value: 'Seychelles', country_code: 'SC' },
  { label: 'Sierra Leone', value: 'Sierra Leone', country_code: 'SL' },
  { label: 'Singapore', value: 'Singapore', country_code: 'SG' },
  { label: 'Sint Maarten', value: 'Sint Maarten', country_code: 'SX' },
  { label: 'Slovakia', value: 'Slovakia', country_code: 'SK' },
  { label: 'Slovenia', value: 'Slovenia', country_code: 'SI' },
  { label: 'Solomon Islands', value: 'Solomon Islands', country_code: 'SB' },
  { label: 'Somalia', value: 'Somalia', country_code: 'SO' },
  { label: 'South Africa', value: 'South Africa', country_code: 'ZA' },
  { label: 'South Korea', value: 'South Korea', country_code: 'KR' },
  { label: 'South Sudan', value: 'South Sudan', country_code: 'SS' },
  { label: 'Spain', value: 'Spain', country_code: 'ES' },
  { label: 'Sri Lanka', value: 'Sri Lanka', country_code: 'LK' },
  { label: 'Sudan', value: 'Sudan', country_code: 'SD' },
  { label: 'Suriname', value: 'Suriname', country_code: 'SR' },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
    country_code: 'SJ',
  },
  { label: 'Swaziland', value: 'Swaziland', country_code: 'SZ' },
  { label: 'Sweden', value: 'Sweden', country_code: 'SE' },
  { label: 'Switzerland', value: 'Switzerland', country_code: 'CH' },
  { label: 'Syria', value: 'Syria', country_code: 'SY' },
  { label: 'Taiwan', value: 'Taiwan', country_code: 'TW' },
  { label: 'Tajikistan', value: 'Tajikistan', country_code: 'TJ' },
  { label: 'Tanzania', value: 'Tanzania', country_code: 'TZ' },
  { label: 'Thailand', value: 'Thailand', country_code: 'TH' },
  { label: 'Togo', value: 'Togo', country_code: 'TG' },
  { label: 'Tokelau', value: 'Tokelau', country_code: 'TK' },
  { label: 'Tonga', value: 'Tonga', country_code: 'TO' },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
    country_code: 'TT',
  },
  { label: 'Tunisia', value: 'Tunisia', country_code: 'TN' },
  { label: 'Turkey', value: 'Turkey', country_code: 'TR' },
  { label: 'Turkmenistan', value: 'Turkmenistan', country_code: 'TM' },
  {
    label: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
    country_code: 'TC',
  },
  { label: 'Tuvalu', value: 'Tuvalu', country_code: 'TV' },
  {
    label: 'U.S. Virgin Islands',
    value: 'U.S. Virgin Islands',
    country_code: 'VI',
  },
  { label: 'Uganda', value: 'Uganda', country_code: 'UG' },
  { label: 'Ukraine', value: 'Ukraine', country_code: 'UA' },
  { label: 'United Kingdom', value: 'United Kingdom', country_code: 'GB' },
  { label: 'United States', value: 'United States', country_code: 'US' },
  { label: 'Uruguay', value: 'Uruguay', country_code: 'UY' },
  { label: 'Uzbekistan', value: 'Uzbekistan', country_code: 'UZ' },
  { label: 'Vanuatu', value: 'Vanuatu', country_code: 'VU' },
  { label: 'Vatican', value: 'Vatican', country_code: 'VA' },
  { label: 'Venezuela', value: 'Venezuela', country_code: 'VE' },
  { label: 'Vietnam', value: 'Vietnam', country_code: 'VN' },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
    country_code: 'WF',
  },
  { label: 'Western Sahara', value: 'Western Sahara', country_code: 'EH' },
  { label: 'Yemen', value: 'Yemen', country_code: 'YE' },
  { label: 'Zambia', value: 'Zambia', country_code: 'ZM' },
  { label: 'Zimbabwe', value: 'Zimbabwe', country_code: 'ZW' },
];

export const PRODUCT_STATUS_OPTIONS = [
  { label: 'Active', value: 'active', actualValue: true },
  { label: 'Inactive', value: 'inactive', actualValue: false },
];

export const VAT_RATE_OPTIONS = [
  { label: '0%', value: 0 },
  { label: '5%', value: 5 },
  { label: '20%', value: 20 },
];

export const METADATA_NAME_OPTIONS = [
  { label: 'Keyword', value: 'keyword' },
  { label: 'Title', value: 'title' },
  { label: 'Description ', value: 'description' },
];

export const PRODUCT_OPTIONS = [
  { label: 'products', value: 'products' },
  { label: 'brands', value: 'brands' },
  { label: 'categories', value: 'categories' },
];

export const VARIANT_ATTRIBUTE_OPTIONS = [
  { label: 'Strength', value: 'Strength' },
  { label: 'Quantity', value: 'Quantity' },
  { label: 'Package_Size', value: 'Package_Size' },
  { label: 'Colour', value: 'Colour' },
  { label: 'Generic_or_Branded', value: 'Generic_or_Branded' },
  { label: 'Dose', value: 'Dose' },
  { label: 'Type', value: 'Type' },
];

export const DIMENSION = {
  product: {
    height: 400,
    width: 400,
  },
  bigBanner: {
    height: 410,
    width: 1296,
  },
  smallBanner: {
    height: 130,
    width: 250,
  },
  blog: {
    height: 300,
    width: 1196,
  },
  category: {
    height: 312,
    width: 186,
  },
  subCategory: {
    height: 400,
    width: 400,
  },
  profile: {
    height: 100,
    width: 100,
  },
  healthGoal: {
    height: 300,
    width: 300,
  },
  landingPage: {
    height: 600,
    width: 600,
  },
};
export const GenderByKey = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
};

export const BANNER_TYPE = [
  {
    label: `Big Banner - w x h : ${DIMENSION.bigBanner.width} x ${DIMENSION.bigBanner.height}`,
    value: 'big',
  },
  {
    label: `Small Banner - w x h : ${DIMENSION.smallBanner.width} x ${DIMENSION.smallBanner.height}`,
    value: 'small',
  },
];

export const deliveryOptions = [
  { label: 'Royal Mail', value: 'royal_mail' },
  { label: 'Quiver', value: 'quiver' },
  { label: 'VIP', value: 'vip' },
];
export const statusOptions = [
  {
    label: 'Placed',
    value: 'placed',
  },
  {
    label: 'Dispatched',
    value: 'dispatched',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
];

export const drugLabelHTML = (data) => {
  const html = `<div style="padding:10px;width:227px;">
                  <p style="font-size: 9px;text-decoration:underline;font-weight:bold;text-align:center;">
                    ${data?.product?.name}
                  </p>
                  <p style="height:50px;font-size: 7px;font-weight:bold;text-align:center;margin-top:5px;">
                    ${
                      data?.instruction && data.instruction != undefined
                        ? data.instruction
                        : ''
                    }
                  </p>
                  <p style="margin-top:5px;display:flex;align-items:center;justify-content:space-between;">
                    <span style="font-size: 9px;font-weight:bold;text-align:left;">
                      ${data?.user?.first_name} ${data?.user?.last_name}
                    </span>
                    <span style="font-size: 7px;font-weight:bold;text-align:right;">
                      ${moment(data?.createdAt).format('DD/MM/YYYY')}
                    </span>
                  </p>
                  <p style="margin-top:10px;text-align:center;font-size: 7px;font-weight:bold;">
                    My London Pharmacy<br>
                    020 3154 4734<br>
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;">
                    45 Newman Street, London W1T 1QE
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;color:red;">
                    Keep out of reach and sight of children
                  </p>
                </div>`;
  return html;
};

export const gonnoreahDrugLabelHTML1 = (data) => {
  const html = `<div style="padding:5px 10px;width:227px;">
                  <p style="font-size: 9px;text-decoration:underline;font-weight:bold;text-align:center;">
                    2 x Suprax 200 mg
                  </p>
                  <p style="height:50px;font-size: 7px;font-weight:bold;text-align:center;margin-top:5px;">
                    To be taken in one go alongside 4 x azithromycin 500mg
                  </p>
                  <p style="margin-top:5px;display:flex;align-items:center;justify-content:space-between;">
                    <span style="font-size: 9px;font-weight:bold;text-align:left;">
                      ${data?.user?.first_name} ${data?.user?.last_name}
                    </span>
                    <span style="font-size: 7px;font-weight:bold;text-align:right;">
                      ${moment(data?.createdAt).format('DD/MM/YYYY')}
                    </span>
                  </p>
                  <p style="margin-top:10px;text-align:center;font-size: 7px;font-weight:bold;">
                    My London Pharmacy<br>
                    020 3154 4734<br>
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;">
                    45 Newman Street, London W1T 1QE
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;color:red;">
                    Keep out of reach and sight of children
                  </p>
                </div>

                <div style="padding:0px 10px;width:227px;">
                  <p style="font-size: 9px;text-decoration:underline;font-weight:bold;text-align:center;">
                    4 x Azithromycin 500 mg
                  </p>
                  <p style="height:50px;font-size: 7px;font-weight:bold;text-align:center;margin-top:5px;">
                   To be taken in one go alongside 2 x Suprax 200mg 
                  </p>
                  <p style="margin-top:5px;display:flex;align-items:center;justify-content:space-between;">
                    <span style="font-size: 9px;font-weight:bold;text-align:left;">
                      ${data?.user?.first_name} ${data?.user?.last_name}
                    </span>
                    <span style="font-size: 7px;font-weight:bold;text-align:right;">
                      ${moment(data?.createdAt).format('DD/MM/YYYY')}
                    </span>
                  </p>
                  <p style="margin-top:10px;text-align:center;font-size: 7px;font-weight:bold;">
                    My London Pharmacy<br>
                    020 3154 4734<br>
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;">
                    45 Newman Street, London W1T 1QE
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;color:red;">
                    Keep out of reach and sight of children
                  </p>
                </div>`;
  return html;
};

export const gonnoreahDrugLabelHTML2 = (data) => {
  const html = `<div style="padding:0px 10px;width:227px;">
                  <p style="font-size: 9px;text-decoration:underline;font-weight:bold;text-align:center;">
                    4 x Azithromycin 500 mg
                  </p>
                  <p style="height:50px;font-size: 7px;font-weight:bold;text-align:center;margin-top:5px;">
                   To be taken in one go alongside 2 x Suprax 200mg 
                  </p>
                  <p style="margin-top:5px;display:flex;align-items:center;justify-content:space-between;">
                    <span style="font-size: 9px;font-weight:bold;text-align:left;">
                      ${data?.user?.first_name} ${data?.user?.last_name}
                    </span>
                    <span style="font-size: 7px;font-weight:bold;text-align:right;">
                      ${moment(data?.createdAt).format('DD/MM/YYYY')}
                    </span>
                  </p>
                  <p style="margin-top:10px;text-align:center;font-size: 7px;font-weight:bold;">
                    My London Pharmacy<br>
                    020 3154 4734<br>
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;">
                    45 Newman Street, London W1T 1QE
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;color:red;">
                    Keep out of reach and sight of children
                  </p>
                </div>`;
  return html;
};

export const deliveryLabel = (data) => {
  const html = `<div class="mt-2 mx-2">
                  <p style="font-size: 15px">
                    ${data?.user?.first_name} ${data?.user?.last_name}
                  </p>
                  <hr />
                  <p style="font-size: 12px">
                    ${
                      data?.address?.address_line2
                        ? `${data?.address?.address_line1}, ${data?.address?.address_line2}`
                        : `${data?.address?.address_line1}`
                    },
                  </p>
                  <p style="font-size: 12px">
                    ${data?.address?.address_line1},
                  </p>
                  <p style="font-size: 12px">
                    ${data?.address?.city}, ${data?.address?.country}
                  </p>
                </div>`;
  return html;
};

export const prescriptionTemplate = (data) => {
  const doctorInfo = data?.orderItem?.reduce(
    (prev, curr, index) => {
      if (
        curr?.product?.type === 'medication' &&
        curr?.answer_status == 'Approved'
      ) {
        let variantInfo = curr?.variant?.attributes
          ? Object.keys(curr?.variant?.attributes).reduce(
              (previous, currentAttr) => {
                return (
                  previous +
                  `<div><span class="fw-bold">${currentAttr} : </span>${curr.variant.attributes[currentAttr]}</div>`
                );
              },
              ``,
            )
          : '';

        prev.medicinesInfoHTML =
          prev.medicinesInfoHTML +
          `<div style="margin-top:12px"> <span class="fw-bold">MEDICINE : </span>
              ${
                curr?.product?.name == 'Ozempic'
                  ? 'Wegovy'
                  : curr?.product?.name
              }</div>
          ${variantInfo} 
          ${
            curr?.instruction
              ? `<div>
              <span class="fw-bold">DOSE INFORMATION : </span> 
              ${curr?.instruction}
            </div>`
              : ''
          }`;

        let doctorName =
          curr?.doctor?.first_name && curr?.doctor?.first_name != undefined
            ? `${curr?.doctor?.first_name} ${curr?.doctor?.last_name}`
            : '';
        let doctorSign =
          curr?.doctor?.doctor_e_sign != undefined
            ? curr?.doctor?.doctor_e_sign
            : '';
        if (!prev.doctorName.includes(doctorName)) {
          prev.doctorName =
            prev.doctorName === ''
              ? doctorName
              : `${prev.doctorName}, ${doctorName}`;
          prev.doctorSign =
            prev.doctorSign === ''
              ? doctorSign
              : `${prev.doctorSign}, ${doctorSign}`;
          return prev;
        } else {
          return prev;
        }
      }
      return prev;
    },
    { doctorName: '', doctorSign: '', medicinesInfoHTML: '' },
  );

  let doctorApprovalDate = data?.status.filter(
    (el) => el.actionType == orderActions?.medStatus,
  );

  doctorApprovalDate = doctorApprovalDate[doctorApprovalDate?.length - 1].date;

  const html =
    `<div style="padding: 20px 20px;" style="width: 100%;">
        <div style="width:406px;">
          <div>
            <img src="${topBanner}" alt="Top Header" style="width:100%; height: auto;" />
          </div>
          <div style="text-align:center;font-weight:bold;width:100%;font-size: 12px;">DISPENSING PRESCRIPTION</div>
          <div style="display:flex;margin-top:10px">
            <div style="padding: 0 10px 0 20px;width:40%;">
              <div style="padding:10px 0px;border-top: 1px solid #77cdc8;font-size:10px;color:#000;line-height:10px">
                <div>PRESCRIPTION NUMBER:</div>
                <div>${data?.prescription_number}</div>
                <div>ORDER NUMBER:</div>
                <div>${data?.order_number}</div>
              </div>
              <div style="padding:10px 0px;border-top: 1px solid #77cdc8;border-bottom: 1px solid #77cdc8;font-size:9px;color:#000;line-height:11px;text-align:center;">
                <div><img src="${emailIcon}" alt="Email Icon" style="width:20px; height: 20px;" /></div>
                <div style="margin-top:4px;">support@mayfairclinic.ae</div>
                <div style="margin-top:10px;"><img src="${phoneIcon}" alt="Phone Icon" style="width:20px; height: 20px;" /></div>
                <div style="margin-top:4px;">+44 1750 491 172</div>
                <div style="margin-top:8px;">45 Newman Street, London W1T 1QE, UK</div>

              </div>
            </div>
            <div style="padding: 0 10px 0 20px;width:60%;">
              <div style="padding:7px 0px;border-top: 1px solid #77cdc8;font-size:11px;color:#000;line-height:10px;text-align:center;">
              <div>${data?.user?.first_name} ${data?.user?.last_name}</div>
              <div>${data?.address?.address_line1}</div>
              <div>${
                data?.address?.address_line2 ? data?.address?.address_line2 : ''
              }</div>
              <div>${data?.address?.city},</div>
              <div>${data?.address?.country}</div>
              <div>${moment(data?.user?.dob).format('DD/MM/YYYY')}</div>
              <div>${GenderByKey[data?.user?.gender]}</div>
            </div>
            <div style="border-top: 1px solid #77cdc8;font-size:9px;color:#000;">` +
    doctorInfo.medicinesInfoHTML +
    `</div>
    </div>
        </div>
        <div style="margin-top:150px">
        <h6 class='text-danger mb-3 fw-bold' style="font-size:10px;">This prescription has been dispensed by ${
          data?.pharmacist?.company_name
        } on ${moment(
      data?.status?.find((el) => el?.status === 'Dispatched by Pharmacist')
        ?.date,
    ).format('DD/MM/YYYY')}.</h6>
          <table style="font-size:9px;border: 1px solid;width:60%;">
            <tr>
              <td style="border-right: 1px solid;border-bottom: 1px solid;padding:0px 2px;">Prescription Approved By</td>
              <td style="border-bottom: 1px solid;padding:0px 2px;">${
                doctorInfo?.doctorName
              }</td>
            </tr>
            <tr>
              <td style="border-right: 1px solid;border-bottom: 1px solid;padding:0px 2px;">E-Signature ID</td>
              <td style="border-bottom: 1px solid;padding:0px 2px;">${
                doctorInfo?.doctorSign
              }</td>
            </tr>
            <tr>
              <td style="border-right: 1px solid;padding:0px 2px;">Date</td>
              <td style="padding:0px 2px;">${moment(doctorApprovalDate).format(
                'DD/MM/YYYY',
              )}</td>
            </tr>
          </table>
        </div>
        <div style="display:flex;height:20px;margin-bottom:40px;">
          <div style="width:40%;"></div>
          <div style="width:60%;border-bottom: 1px solid #77cdc8;"></div>
        </div>
        <div>
          <img src="${bottomBanner}" alt="Bottom Footer" style="width:100%; height: auto;" />
        </div>
      </div>
    </div>`;
  return html;
};

export const staticIDs = {
  weight: '636bb1a8b316010519546a57',
  height: '636bb1a8b316010519546a55',
  gp_question: '63cf77c0069bf41adade01d7',
};

export const gpAddressSubQuestionIDs = [
  '63cf7a6c069bf41adade02c0', // Main Question
  '63cf9d7d069bf41adade048c', // Address 1
  '63cf9d7d069bf41adade048d', // Address 2
  '63cf9d7d069bf41adade048e', // Address 3
  '63cf9d7d069bf41adade048f', // City
  '63cf9d7d069bf41adade0490', // Postcode
  '63cf9d7d069bf41adade0491', //  GP email address
];

export const gpEmailKey = '63cf9d7d069bf41adade0491';
export const gpNameKey = '63cf7a6c069bf41adade02c0';

export const subQuestionIDAndKeys = {
  '63cf9d7d069bf41adade048c': 'Address1',
  '63cf9d7d069bf41adade048d': 'Address2',
  '63cf9d7d069bf41adade048e': 'Address3',
  '63cf9d7d069bf41adade048f': 'City',
  '63cf9d7d069bf41adade0490': 'Postcode',
  '63cf9d7d069bf41adade0491': 'email',
};

export const gpAddressSubQuestionIDAndKeys = {
  '63cf7a6c069bf41adade02c0': 'Main Question',
  '63cf9d7d069bf41adade048c': 'Address 1',
  '63cf9d7d069bf41adade048d': 'Address 2',
  '63cf9d7d069bf41adade048e': 'Address 3',
  '63cf9d7d069bf41adade048f': 'City',
  '63cf9d7d069bf41adade0490': 'Postcode',
  '63cf9d7d069bf41adade0491': 'Email',
};

export const orderActions = {
  orderStatus: 'orderStatus',
  Quantity: 'qty',
  medStatus: 'medStatus',
  pharmacyReject: 'pharReject',
  assignment: 'assignment',
};

export const headerTabs = {
  productTab: [
    'product',
    'category',
    'keyword',
    'brand',
    'featured-product',
    'general-health-questions',
    'health-goals',
  ],
  promotionTab: ['brand-promotion', 'product-promotion', 'offer-title'],
  orderTab: [
    'orders',
    'orders-pending',
    'orders-dispatched',
    'orders-cancelled',
    'pharmacist-rejected',
    'lexisnexis-rejected',
    'orders-approved',
    'orders-assigned',
    'orders-subscription',
    'orders-subscription-cancelled',
    'orders-gpLetterSection',
  ],
  userTab: ['admin-doctor', 'users', 'duplicate-users'],
  stockTab: ['stock-upload', 'stock-scan'],
  reportingTab: ['pharmacist-reporting', 'accounting', 'doctor-reporting'],
  settingTab: ['user-profile'],
  gpLetters: ['gp-letters', 'gp-letters-download'],
};

export const SubscriptionSpanOptions = [
  { label: '1 Month', value: '1_month' },
  { label: '3 Months', value: '3_months' },
];

export const orderSearchbarPlaceholder =
  'Search by order number, user name, email and order item...';

export const RESTRICT_STATEMENT =
  'Your response suggests that this medication may not be appropriate for online purchase. For further advice, please contact us or your GP.';
