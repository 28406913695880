import React from 'react';
import CustomModal from './../../common/UI/CustomModal';
import profileImg from './../../assets/images/profile-image.jpg';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import UserPastOrders from './UserPastOrders';

const UserDetailsModal = ({ show, handleToggle, userInfo }) => {
  const convertAddress = (addressList) => {
    return addressList && addressList.length > 0
      ? addressList
          .map((add, ind) => {
            return `${ind + 1}. ${add.title} <br/>${add.address_line1}\n  ${
              add?.address_line2 ? add?.address_line2 : ''
            }\n ${add.city}, ${add.zip_code}, ${add.country} ${
              add.is_primary ? ' (Primary)' : ''
            }`;
          })
          .join('<br/>')
      : 'Not available';
  };

  return (
    <CustomModal
      show={show}
      title={`${userInfo?.first_name} ${userInfo?.last_name} Details`}
      handleToggle={handleToggle}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2" style={{ textAlign: 'center' }}>
            <img
              src={userInfo?.photo?.location || profileImg}
              style={{
                borderRadius: '50%',
                width: '90px',
                height: '90px',
                objectFit: 'cover',
              }}
            />
          </div>
          <div className="col-md-10">
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Full Name: </p>
              </Col>
              <Col md={4}>
                <p className="des_3">{`${userInfo?.first_name} ${userInfo?.last_name}`}</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Email:</p>
              </Col>
              <Col md={4}>
                <a href={`mailto:${userInfo?.email}`} className="text-wrap">
                  {userInfo?.email}
                </a>
              </Col>
              <Col md={2}>
                <p className="des_2">Phone:</p>
              </Col>
              <Col md={4}>
                <a href={`tel:${userInfo?.phone}`}>{userInfo?.phone}</a>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Gender:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">{userInfo?.gender}</p>
              </Col>
              <Col md={2}>
                <p className="des_2">DOB:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">
                  {moment(userInfo?.dob).format('DD-MM-YYYY')}
                </p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Role:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">{userInfo?.role}</p>
              </Col>
              <Col md={2}>
                <p className="des_2">Status:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">
                  {userInfo?.status ? 'Active' : 'Inactive'}
                </p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Scorecard:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">{userInfo?.verified?.scoreCard || ''}</p>
              </Col>
              <Col md={2}>
                <p className="des_2">Smartscore:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">{userInfo?.verified?.smartScore || ''}</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Result Text:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">{userInfo?.verified?.resultText || ''}</p>
              </Col>
              <Col md={2}>
                <p className="des_2">Email risk:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">{userInfo?.verified?.emailRisk || ''}</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Profile URL:</p>
              </Col>
              <Col md={10}>
                <a
                  className="des_3"
                  href={userInfo?.verified?.profileURL || '#'}
                  target="_blank"
                  rel="noreferrer"
                >
                  {userInfo?.verified?.profileURL || ''}
                </a>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Joined Date:</p>
              </Col>
              <Col md={4}>
                <p className="des_3">
                  {moment(userInfo?.createdAt).format('DD-MM-YYYY')}
                </p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}>
                <p className="des_2">Addresses:</p>
              </Col>
              <Col md={10}>
                <p
                  className="des_3"
                  dangerouslySetInnerHTML={{
                    __html: convertAddress(userInfo?.addressList) || '',
                  }}
                ></p>
              </Col>
            </Row>
          </div>
          <Row>
            <UserPastOrders userId={userInfo?._id} />
          </Row>
        </div>
      </div>
    </CustomModal>
  );
};

export default UserDetailsModal;
