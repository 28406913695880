import React, { useEffect, useState } from "react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import services from "../../../api/api";
import { useParams } from "react-router-dom";
import CustomDropdown from "../../../common/UI/Dropdown";
import AddAndEditModal from "../../../common/UI/CustomModal/addAndEditModal";
import { toastError, toastSuccess } from "../../../common/UI/Toasts";
import {
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from "../../../utils/helpers";
import { staticIDs } from "../../../common/constants";
import moment from "moment";
import GPAddressModal from "./GPAddressModal";
import { useDispatch } from "react-redux";
import { getOrderById } from "../../../store/reducers/order";

const Status_Options = [
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
];

const ProductQuestions = (props) => {
  const { id, productId } = useParams();
  const dispatch = useDispatch();
  const { OrderItem, orderStatus } = props;
  const genAnswers = OrderItem?.general_ans;
  const height = genAnswers?.find((el) => el?.question_id === staticIDs?.height)
    ?.question?.answer;
  const weight = genAnswers?.find((el) => el?.question_id === staticIDs?.weight)
    ?.question?.answer;
  const catAnswers =
    OrderItem?.sub_cat_ans?.length && OrderItem?.sub_cat_ans[0];
  const folowUpAnswers = OrderItem?.follow_up_ans;
  const defaultStatus = {
    label: "InReview",
    value: "InReview",
  };
  const [BMI, setBMI] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isGenAnswersExpanded, setIsGenAnswersExpanded] = useState(true);
  const [isCatAnswersExpanded, setIsCatAnswersExpanded] = useState(true);
  const [isFollowUpAnswersExpanded, setIsFollowUpAnswersExpanded] =
    useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hgtWgtModal, setHgtWgtModal] = useState({ show: false });
  const [updatedHeight, setUpdatedHeight] = useState({});
  const [updatedWeight, setUpdatedWeight] = useState({});
  const [statusValue, setStatusValue] = useState(defaultStatus);
  // const [gpAddressModal, setGPAddressModal] = useState({
  //   show: false,
  //   data: {},
  //   questionId: null,
  //   orderId: null,
  // });

  useEffect(() => {
    const status = Status_Options.find(
      (el) => el?.value === OrderItem?.answer_status
    );

    status && setStatusValue(status);
  }, []);

  useEffect(() => {
    calculateBMI();
    setUpdatedHeight({
      answer: height,
    });
    setUpdatedWeight({
      answer: weight,
    });
  }, [height, weight]);

  const toggleExpanded = (whichToggle) => {
    if (whichToggle === "generalHealth") {
      setIsGenAnswersExpanded((prev) => !prev);
    } else if (whichToggle === "category") {
      setIsCatAnswersExpanded((prev) => !prev);
    } else if (whichToggle === "follow-up") {
      setIsFollowUpAnswersExpanded((prev) => !prev);
    } else {
      setIsExpanded((prev) => !prev);
    }
  };

  const toggleModal = (modalType) => {
    if (modalType == "status") {
      setIsModalOpen(false);
      setStatusValue(defaultStatus);
    } else {
      setHgtWgtModal({
        show: false,
      });
      setUpdatedHeight({
        answer: height,
      });
      setUpdatedWeight({
        answer: weight,
      });
    }
  };

  const changeStatus = () => {
    // api call
    const payload = {
      status: statusValue?.value,
    };
    services
      .setStatusOfProductApproval(OrderItem?._id, payload)
      .then((res) => {
        setIsModalOpen(false);
        toastSuccess("Changed status successfully");
      })
      .catch((err) => {
        console.log("err", err);
        setIsModalOpen(false);
        setStatusValue(defaultStatus);
        toastError(err);
      });
  };

  const calculateBMI = () => {
    let bmi = (weight / height / height) * 10000;
    setBMI(bmi.toFixed(2));
  };

  // const toggleGPAddressModal = (type, data = null) => {
  //   if (type === 'modal-open') {
  //     const gpquestion = OrderItem.general_ans.find(
  //       (g) => g.question_id === staticIDs.gp_question,
  //     );
  //     setGPAddressModal((prev) => ({
  //       ...prev,
  //       show: true,
  //       data: gpquestion,
  //     }));
  //   } else if (type === 'modal-close') {
  //     setGPAddressModal((prev) => ({
  //       ...prev,
  //       show: false,
  //       data: null,
  //     }));
  //   } else if (type === 'update') {
  //     // api call
  //     api
  //       .updateOrderGPAddress({
  //         orderId: OrderItem.order_id,
  //         generalAnsGpObject: data,
  //       })
  //       .then((res) => {
  //         toastSuccess('GP Address updated.');
  //         dispatch(getOrderById(OrderItem.order_id));
  //         setGPAddressModal((prev) => ({
  //           ...prev,
  //           show: false,
  //           data: null,
  //         }));
  //       });
  //   }
  // };

  const updateHeightWeight = (type) => {
    let payload = {
      id: id,
      updateHeightWeight: true,
    };

    if (type == "Height")
      payload = {
        ...payload,
        height: {
          ...updatedHeight,
        },
      };
    else if (type == "Weight")
      payload = {
        ...payload,
        weight: {
          ...updatedWeight,
        },
      };

    services
      .updateHeightWeight(payload)
      .then((res) => {
        console.log("res", res);
        toastSuccess(`Updated ${hgtWgtModal.openFor} Successfully`);
        setHgtWgtModal({
          show: false,
        });
        dispatch(getOrderById(id));
      })
      .catch((err) => {
        console.log("err", err);
        toastError(err.message);
      });
  };

  const gpQues = OrderItem.general_ans.find(
    (g) => g.question_id === staticIDs.gp_question
  );

  const showGPEditBtn = gpQues?.question?.answer === "yes";

  const handleChangeOfHgtWgt = (value) => {
    if (hgtWgtModal.openFor == "Height") {
      // convert cm to feet and inch
      let height = {
        answer: value,
        answerInCms: value,
      };
      let feet = Math.floor(value / 30.48);
      let inch = Math.round((value % 30.48) * 0.39);
      if (inch === 12) {
        feet = feet + 1;
        inch = 0;
      }
      height["answerInFeet"] = feet;
      height["answerInInch"] = inch;
      setUpdatedHeight(height);
    } else if (hgtWgtModal.openFor == "Weight") {
      // convert kg to stone
      let weight = {
        answer: value,
        answerInKgs: value,
      };
      let stone = Math.floor(value / 6.35);
      let pound = Math.round((value % 6.35) * 2.2);
      if (pound === 14) {
        stone = stone + 1;
        pound = 0;
      }
      weight["answerInStone"] = stone;
      weight["answerInPound"] = pound;
      setUpdatedWeight(weight);
    }
  };

  return (
    <div>
      <div className="header_top " style={{ justifyContent: "space-between" }}>
        <h3>Related Questions</h3>
        <div className="d-flex ms-auto align-items-center">
          <p className="fw-bold me-3">Status</p>

          <CustomDropdown
            className="me-3"
            options={Status_Options}
            isSearchable={false}
            height={"40px"}
            // placeholderMarginTop={'-5px'}
            placeholder={"Select"}
            placeholderColor={"#9aa5ad"}
            border={"1 px solid #9aa5ad"}
            containerWidth={"150px"}
            onChange={(value) => {
              if (
                value.value === "Approved" &&
                (!OrderItem.instruction || OrderItem.instruction == "")
              ) {
                console.log("in if");
                toastError("Please add instruction first.");
                return;
              }
              setIsModalOpen(true);
              setStatusValue(value);
            }}
            value={statusValue}
            isDisabled={
              isUserDoctor()
                ? statusValue?.value === "InReview"
                  ? false
                  : true
                : true
            }
          />
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggleExpanded();
          }}
        >
          {isExpanded ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </div>
      </div>
      {isExpanded ? (
        <div className="ms-4">
          {/* General Health Question Section */}
          <div>
            {/* Header */}
            <div
              className="header_top"
              style={{ justifyContent: "space-between" }}
            >
              <h3>General Health Questions</h3>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleExpanded("generalHealth");
                }}
              >
                {isGenAnswersExpanded ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
              </div>
            </div>
            {/* Listing */}
            {isGenAnswersExpanded && genAnswers ? (
              <div
                className="mt-2"
                style={{
                  backgroundColor: "#ffffff",
                  padding: "4px",
                  borderRadius: "4px",
                }}
              >
                BMI Section
                <div
                  // key={el?._id}
                  className="mb-2 d-flex"
                  style={{
                    padding: "7px",
                    backgroundColor: "#e9ecefa3",
                    borderRadius: "4px",
                    // color: el?.question?.hasMarkedRestricted ? 'red' : 'black',
                  }}
                >
                  <div
                    className="fw-bold ms-2"
                    style={{
                      width: "25%",
                    }}
                  >
                    Height :
                    <span
                      className="p-2 ms-2"
                      style={{
                        backgroundColor: "white",
                        fontWeight: "normal",
                      }}
                    >
                      {height} {`cm`}
                    </span>
                  </div>
                  <div
                    className="fw-bold"
                    style={{
                      width: "25%",
                    }}
                  >
                    Weight :
                    <span
                      className="p-2 ms-2"
                      style={{
                        backgroundColor: "white",
                        fontWeight: "normal",
                      }}
                    >
                      {weight} {` kg`}
                    </span>
                  </div>
                  <div
                    className="fw-bold"
                    style={{
                      width: "25%",
                    }}
                  >
                    BMI :
                    <span
                      className="p-2 ms-2"
                      style={{
                        backgroundColor: "white",
                        fontWeight: "normal",
                      }}
                    >
                      {BMI} {` kg/cm²`}
                    </span>
                  </div>

                  <div>
                    {isUserAdmin() && orderStatus === "placed" ? (
                      <>
                        <button
                          className="btn btn-primary mx-2 py-1 px-3"
                          onClick={() =>
                            setHgtWgtModal({
                              show: true,
                              openFor: "Height",
                            })
                          }
                        >
                          Height
                        </button>
                        <button
                          className="btn btn-primary mx-2 py-1 px-3"
                          onClick={() =>
                            setHgtWgtModal({
                              show: true,
                              openFor: "Weight",
                            })
                          }
                        >
                          Weight
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
                {genAnswers?.map((el, index) => {
                  return (
                    <>
                      {!(
                        el?.question_id == staticIDs?.height ||
                        el?.question_id == staticIDs?.weight
                      ) ? (
                        <div
                          key={el?._id}
                          className="mb-2"
                          style={{
                            padding: "7px",
                            backgroundColor: "#e9ecefa3",
                            borderRadius: "4px",
                            color: el?.question?.hasMarkedRestricted
                              ? "red"
                              : "black",
                          }}
                        >
                          <div
                            className="d-flex fs-16"
                            style={{
                              borderRadius: "4px",
                              // padding: '0.18rem',
                              background: "#ffffff",
                              justifyContent: "space-between",
                              fontSize: "20px",
                              fontWeight: "500",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            <div className="px-2 d-flex">
                              <span>Q)</span>{" "}
                              {el?.question?.isHTML ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: el?.question?.question,
                                  }}
                                  className="ms-2"
                                />
                              ) : (
                                <span className="ms-2">
                                  {el?.question?.question}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="ms-3">
                            {el.question.questionType.value === "file" ? (
                              <img src={el.question?.answer?.location} />
                            ) : el.question.questionType.value === "date" ? (
                              moment(el?.question?.answer).format("DD/MM/yyyy")
                            ) : (
                              el?.question?.answer
                            )}
                          </div>
                          {/* Sub Questions */}
                          {el?.question?.subQuestions &&
                            el?.question?.subQuestions
                              .filter((elem) => {
                                if (
                                  el?.question?.questionType?.value ===
                                  "boolean"
                                ) {
                                  return (
                                    elem?.selectedAnswer?.value ===
                                    el?.question?.answer
                                  );
                                } else if (
                                  el?.question?.questionType?.value === "select"
                                ) {
                                  if (
                                    el?.question?.answer?.length &&
                                    el?.question?.answer.includes(
                                      elem?.selectedAnswer?.value
                                    )
                                  )
                                    return elem;
                                }
                              })
                              ?.map((el, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="mt-1 ms-5"
                                    style={{
                                      padding: "5px",
                                      // backgroundColor: '#ffffff',
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <div
                                      className="d-flex fs-16"
                                      style={{
                                        borderRadius: "4px",
                                        // padding: '0.18rem',
                                        background: "#ffffff",
                                        justifyContent: "space-between",
                                        fontSize: "20px",
                                        fontWeight: "500",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                    >
                                      <div className="px-2 d-flex">
                                        <span>Q)</span>{" "}
                                        {el?.isHTML ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: el?.question,
                                            }}
                                            className="ms-2"
                                          />
                                        ) : (
                                          <span className="ms-2">
                                            {el?.question}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="ms-3">
                                      Answer:{" "}
                                      {el?.questionType?.value === "file" ? (
                                        <img src={el?.answer?.location} />
                                      ) : el?.questionType?.value === "date" ? (
                                        moment(el?.answer).format("DD/MM/yyyy")
                                      ) : el?.questionType?.value ===
                                        "gp_select" ? (
                                        el?.answer?.label
                                      ) : (
                                        el?.answer
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
            ) : null}
          </div>
          {/* Category Specific Question Section */}
          {isUserAdmin() || isUserDoctor() || isUserPharmacist() ? (
            <div>
              {/* Header */}
              <div
                className="header_top"
                style={{ justifyContent: "space-between" }}
              >
                <h3>Category Specific Questions</h3>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleExpanded("category");
                  }}
                >
                  {isCatAnswersExpanded ? (
                    <FontAwesomeIcon icon={faChevronDown} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                </div>
              </div>
              {/* Listing */}
              {isCatAnswersExpanded && catAnswers ? (
                <div
                  className="mt-2"
                  style={{
                    backgroundColor: "#ffffff",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {catAnswers?.map((el, index) => {
                    return (
                      <div
                        key={el?._id}
                        className="mb-2"
                        style={{
                          padding: "7px",
                          backgroundColor: "#e9ecefa3",
                          borderRadius: "4px",
                          color: el?.answer?.hasMarkedRestricted
                            ? "black"
                            : "black",
                        }}
                      >
                        <div
                          className="d-flex fs-16"
                          style={{
                            borderRadius: "4px",
                            // padding: '0.18rem',
                            background: "#ffffff",
                            justifyContent: "space-between",
                            fontSize: "20px",
                            fontWeight: "500",
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          <div className="px-2 d-flex">
                            <span>Q)</span>{" "}
                            {el?.answer?.isHTML ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: el?.answer?.question,
                                }}
                                className="ms-2"
                              />
                            ) : (
                              <span className="ms-2">
                                {el?.answer?.question}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="ms-3">
                          {el.answer.questionType.value === "file" ? (
                            <img src={el.answer.answer.location} />
                          ) : el.answer.questionType.value === "date" ? (
                            `${moment(el?.answer?.answer).format("DD/MM/yyyy")}`
                          ) : (
                            el?.answer?.answer
                          )}
                        </div>
                        {/* Sub Questions */}
                        {el?.answer?.subQuestions &&
                          el?.answer?.subQuestions
                            ?.filter((elem) => {
                              if (
                                el?.answer?.questionType?.value === "boolean"
                              ) {
                                return (
                                  elem?.selectedAnswer?.value ===
                                  el?.answer?.answer
                                );
                              } else if (
                                el?.answer?.questionType?.value === "select"
                              ) {
                                return (
                                  el?.answer?.answer?.length &&
                                  el?.answer?.answer.includes(
                                    elem.selectedAnswer?.value
                                  )
                                );
                              }
                            })
                            .map((el, index) => {
                              return (
                                <div
                                  key={index}
                                  className="ms-5"
                                  style={{
                                    padding: "5px",
                                    color: el?.hasMarkedRestricted
                                      ? "black"
                                      : "black",
                                    // backgroundColor: '#ffffff',
                                    borderRadius: "4px",
                                  }}
                                >
                                  <div
                                    className="d-flex fs-16"
                                    style={{
                                      borderRadius: "4px",
                                      // padding: '0.18rem',
                                      background: "#ffffff",
                                      justifyContent: "space-between",
                                      fontSize: "20px",
                                      fontWeight: "500",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                  >
                                    <div className="px-2 d-flex">
                                      <span>Q)</span>
                                      {"    "}
                                      {el?.isHTML ? (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: el?.question,
                                          }}
                                          className="ms-2"
                                        />
                                      ) : (
                                        <span className="ms-2">
                                          {el?.question}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="ms-3">
                                    {el.questionType.value === "file" ? (
                                      <img src={el?.answer?.location} />
                                    ) : el.questionType.value === "date" ? (
                                      `${moment(el?.answer).format(
                                        "DD/MM/yyyy"
                                      )}`
                                    ) : (
                                      el?.answer
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          ) : null}

          {/* Follow Up Question Section */}
          {(isUserAdmin() || isUserDoctor() || isUserPharmacist()) &&
          folowUpAnswers.length ? (
            <div>
              {/* Header */}
              <div
                className="header_top"
                style={{ justifyContent: "space-between" }}
              >
                <h3>Follow Up Questions</h3>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleExpanded("follow-up");
                  }}
                >
                  {isFollowUpAnswersExpanded ? (
                    <FontAwesomeIcon icon={faChevronDown} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                </div>
              </div>
              {/* Listing */}
              {isFollowUpAnswersExpanded && folowUpAnswers ? (
                <div
                  className="mt-2"
                  style={{
                    backgroundColor: "#ffffff",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {folowUpAnswers?.map((el, index) => {
                    return (
                      <div
                        key={el?._id}
                        className="mb-2"
                        style={{
                          padding: "7px",
                          backgroundColor: "#e9ecefa3",
                          borderRadius: "4px",
                          color: el?.hasMarkedRestricted ? "black" : "black",
                        }}
                      >
                        <div
                          className="d-flex fs-16"
                          style={{
                            borderRadius: "4px",
                            // padding: '0.18rem',
                            background: "#ffffff",
                            justifyContent: "space-between",
                            fontSize: "20px",
                            fontWeight: "500",
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          <div className="px-2 d-flex">
                            <span>Q)</span>{" "}
                            {el?.isHTML ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: el?.question,
                                }}
                                className="ms-2"
                              />
                            ) : (
                              <span className="ms-2">{el?.question}</span>
                            )}
                          </div>
                        </div>
                        <div className="ms-3">
                          {el.questionType.value === "file" ? (
                            <img src={el.answer.location} />
                          ) : el.questionType.value === "date" ? (
                            `${moment(el?.answer).format("DD/MM/yyyy")}`
                          ) : (
                            el?.answer
                          )}
                        </div>
                        {/* Sub Questions */}
                        {el?.subQuestions &&
                          el?.subQuestions
                            ?.filter((elem) => {
                              if (el?.questionType?.value === "boolean") {
                                return (
                                  elem?.selectedAnswer?.value === el?.answer
                                );
                              } else if (el?.questionType?.value === "select") {
                                return (
                                  el?.answer?.length &&
                                  el?.answer.includes(
                                    elem.selectedAnswer?.value
                                  )
                                );
                              }
                            })
                            .map((el, index) => {
                              return (
                                <div
                                  key={index}
                                  className="ms-5"
                                  style={{
                                    padding: "5px",
                                    color: el?.hasMarkedRestricted
                                      ? "black"
                                      : "black",
                                    // backgroundColor: '#ffffff',
                                    borderRadius: "4px",
                                  }}
                                >
                                  <div
                                    className="d-flex fs-16"
                                    style={{
                                      borderRadius: "4px",
                                      // padding: '0.18rem',
                                      background: "#ffffff",
                                      justifyContent: "space-between",
                                      fontSize: "20px",
                                      fontWeight: "500",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                  >
                                    <div className="px-2 d-flex">
                                      <span>Q)</span>
                                      {"    "}
                                      {el?.isHTML ? (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: el?.question,
                                          }}
                                          className="ms-2"
                                        />
                                      ) : (
                                        <span className="ms-2">
                                          {el?.question}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="ms-3">
                                    {el.questionType.value === "file" ? (
                                      <img src={el?.answer?.location} />
                                    ) : el.questionType.value === "date" ? (
                                      `${moment(el?.answer).format(
                                        "DD/MM/yyyy"
                                      )}`
                                    ) : (
                                      el?.answer
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
      {isModalOpen ? (
        <AddAndEditModal
          action="Change"
          module="Status"
          handleToggle={() => toggleModal("status")}
          handleAction={changeStatus}
          show={isModalOpen}
        >
          Are you sure you want to change status to{" "}
          <strong>{statusValue?.label}</strong>?
        </AddAndEditModal>
      ) : null}
      {hgtWgtModal.show ? (
        <AddAndEditModal
          action="Edit"
          module={hgtWgtModal?.openFor}
          handleToggle={() => toggleModal("heightWeight")}
          handleAction={() => updateHeightWeight(hgtWgtModal?.openFor)}
          show={hgtWgtModal.show}
        >
          <p className="text-primary">{`Please Enter ${
            hgtWgtModal?.openFor
          } in ${
            hgtWgtModal?.openFor == "Height" ? "Cemtimeters." : "Kilograms."
          }`}</p>
          <label className="form-label">{hgtWgtModal?.openFor}</label>
          <input
            className="form-control"
            type="number"
            min="0"
            value={
              hgtWgtModal.openFor == "Height"
                ? updatedHeight.answer
                : updatedWeight.answer
            }
            onKeyDown={(e) => {
              if (e.code === "Minus" || e.code === "KeyE") {
                e.preventDefault();
              }
            }}
            onChange={(e) => handleChangeOfHgtWgt(e.target.value)}
          />
        </AddAndEditModal>
      ) : null}
      {/* {gpAddressModal.show && (
        <GPAddressModal
          show={gpAddressModal.show}
          toggle={toggleGPAddressModal}
          data={gpAddressModal.data}
        />
      )} */}
    </div>
  );
};

export default ProductQuestions;
