import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTable from "../../../common/UI/ReactTable";
import eyeIcon from "../../../assets/images/eye.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../../api/api";
import { useSelector } from "react-redux";
import { isUserAdmin } from "../../../utils/helpers";

const PastOrders = (props) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    if (isUserAdmin()) {
      getUsersList();
    }
  }, []);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const getUsersList = () => {
    api
      .getUsers({ showall: true, role: "pharmacist,doctor,admin" })
      .then((res) => {
        setUsersList(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const columns = [
    {
      Header: "Order Id",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.order_number}</span>;
      },
    },
    {
      Header: "Products",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return row.original?.orderItem.map((el, ind) => (
          <span key={el._id}>
            {ind + 1}
            {". "}
            {el?.product?.name}
            <br />
          </span>
        ));
      },
    },
    {
      Header: "Status Logs",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return row.original.status
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((el, ind) => {
            // id --- assigned to who
            // user_id --- done by
            let doneBy = el?.user_id;
            // if (el?.actionType === 'assignment') findThisUser = el?.id;
            let user_name = "";
            let assigned_user = "";
            if (el?.id || el?.user_id) {
              const userObject = usersList.find((el) => el._id === doneBy);
              if (el?.actionType == "assignment") {
                const assignedUser = usersList.find((e) => {
                  return e._id == el?.id;
                });

                if (assignedUser) {
                  assigned_user = `${assignedUser.first_name} ${assignedUser.last_name}`;
                }
              }
              if (userObject) {
                user_name = `${userObject.first_name} ${userObject.last_name}`;
              }
            }
            return (
              <span key={ind}>
                {ind + 1}
                {". "}
                {el.status}
                {assigned_user ? `(${assigned_user})` : null}
                {user_name ? `- ${user_name}` : null} (
                {moment(el.date).format("DD/MM/YYYY, hh:mm a")}
                )
                <br />
              </span>
            );
          });
      },
    },
    {
      Header: "Order Total",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>AED {row.original.total}</span>;
      },
    },
    {
      Header: "Action",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              navigate(`/vieworder/${row.original._id}`);
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={eyeIcon} alt="view" width="24" height="24" />
          </span>
        );
      },
    },
  ];
  return (
    <div>
      <div className="header_top " style={{ justifyContent: "space-between" }}>
        <h3>Past Orders</h3>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggleExpanded();
          }}
        >
          {isExpanded ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </div>
      </div>
      {isExpanded ? (
        <ReactTable
          title="Status Logs"
          tableColumns={columns}
          tableData={props.data || []}
          seeAll={true}
        />
      ) : null}
    </div>
  );
};

export default PastOrders;
