import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Form, Formik } from 'formik';

import ImageInput from '../../../common/FormComponents/ImageInput';
import Input from '../../../common/FormComponents/Input';

import { DIMENSION } from '../../../common/constants';
import TextEditor from '../../../common/TextEditor';
import TextError from '../../../common/FormComponents/TextError';

const LandingpageDetails = (props) => {
  const {
    productData,
    handlePrevious,
    handleSubmit,
    isSubmitDisabled,
    productId,
    validationSchema,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 mt-2 mb-4">
          <hr />
        </div>
        <div className="col-12">
          <h4 className="middle_title">Landing Page Details</h4>
        </div>
        <Formik
          initialValues={productData}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {(formik) => {
            return (
              <Form>
                <div className="middle_main_2">
                  <div className="row">
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Title"
                        labelClassname="form-label"
                        name="landingpage_title"
                        type="text"
                        className="form-control form-input"
                        placeholder="Title"
                        isRequired
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-xl-8 form-group">
                        <label className="form-label">
                          Description
                          <span className="text-danger">*</span>
                        </label>
                        <TextEditor
                          name="landingpage_description"
                          value={formik.values.landingpage_description}
                          className={'position-relative'}
                          onEditorChange={(value) => {
                            formik.setFieldValue(
                              'landingpage_description',
                              value,
                              true,
                            );
                          }}
                          editorHeight="30vh"
                        />
                        {formik.touched['landingpage_description'] &&
                          formik.errors['landingpage_description'] && (
                            <TextError>
                              {formik.errors['landingpage_description']}
                            </TextError>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-1 mb24">
                        <hr />
                      </div>
                      <div className="col-12">
                        <h4 className="middle_title">
                          Image
                          <span className="text-danger ml-1">*</span>
                        </h4>
                      </div>
                    </div>
                    <ImageInput
                      label={`Upload Image (w x h: ${DIMENSION.landingPage.width} x ${DIMENSION.landingPage.height})`}
                      accept="image/png, image/svg, image/jpeg, image/jpg, image/webp"
                      name={'landingpage_image'}
                      id={'landingpage_image'}
                      imagesLimit={1}
                      productId={productId}
                      imageDimention={DIMENSION.landingPage}
                      isRequired
                    />
                  </div>
                </div>{' '}
                <div
                  className="footer_main"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div>
                    <button
                      onClick={() => handlePrevious()}
                      type="button"
                      className="btn btn-sky my-2 me-2"
                    >
                      Previous
                    </button>
                  </div>
                  <button
                    disabled={isSubmitDisabled}
                    type="submit"
                    className="btn btn-success my-2 me-2"
                  >
                    {isSubmitDisabled && (
                      <span className="submit-loader">
                        <Spinner animation="border" size="sm" />
                      </span>
                    )}{' '}
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default LandingpageDetails;
